import i18n from "@/config/i18n";
import { RuntimeConfig } from "@/models/RuntimeConfig";
import { DialogType } from "@/models/Symbols/Dialog";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";
import { readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import useDialog from "./useDialog";
import useRuntimeConfig from "./useRuntimeConfig";
import { postMessage } from "@/helpers/messageHandler";

axios.defaults.withCredentials = true;

const devMode = process.env.NODE_ENV !== "production";
const serviceUnavailable = ref(false);
const apiTokenKey = "ORNO";

const useApi = (displayErrors = true, useBaseUrl = true) => {
    const { getRuntimeConfig } = useRuntimeConfig();
    const { t } = useI18n();

    const { displayDialog } = useDialog();

    const setToken = (value: string) => Cookies.set(apiTokenKey, value, { sameSite: "none", secure: true });
    const removeToken = () => {
        Cookies.remove(apiTokenKey);
    };

    const getUser = () => {
        const storage = JSON.parse(localStorage.getItem("user") ?? "{}");
        return storage?.user?.id;
    };

    const setTokenFromResponse = (resp: AxiosResponse) => {
        if (resp?.headers["authorization"]) {
            setToken(resp.headers["authorization"]);
        }
    };

    const getBaseUrl = (c: RuntimeConfig) => {
        return useBaseUrl ? c.apiBaseUrl : "";
    };

    const post = <T>(url: string, data: unknown, headers: Record<string, string> = {}, signal?: AbortSignal): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.post<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("POST", data, headers, signal))),
        );
    };

    const get = <T>(url: string, data: unknown, headers: Record<string, string> = {}, signal?: AbortSignal): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.get<T>(`${getBaseUrl(c)}${url}`, getRequestConfig("GET", data, headers, signal))),
        );
    };

    const del = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) => execute<T>(axios.delete<T>(`${getBaseUrl(c)}${url}`, getRequestConfig("DELETE", data, headers))));
    };

    const put = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) => execute<T>(axios.put<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("PUT", data, headers))));
    };

    const patch = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.patch<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("PATCH", data, headers))),
        );
    };

    const execute = <T>(fetcher: Promise<AxiosResponse<unknown>>): Promise<AxiosResponse<T>> => {
        return new Promise((resolve, reject) => {
            fetcher
                .then((resp) => {
                    //setTokenFromResponse(resp);
                    resolve(resp as AxiosResponse<T>);
                })
                .catch((err) => {
                    if (err?.response?.status === 401 || 
                    	err?.response?.data.errorCode == 105 ||
                    	err?.response?.data.errorCode == 111) {
                    	
                        sessionStorage.clear();
                        localStorage.clear();
                        removeToken();

                        displayDialog({
                            title: err?.response?.data.errorCode == 111 ? t("messages.reauthenticationRequired.title") : t("messages.sessionExpired.title"),
                            message: err?.response?.data.errorCode == 111 ? t("messages.reauthenticationRequired.body") : t("messages.sessionExpired.body"),
                            yes: t("button.ok"),
                            callback: () => {
                            	if (navigator.userAgent?.includes('MobileApp')) {
                            		postMessage({ 'type' : 'sessionexpired' });
                            	}
                            	else {
                            		
                            		switch (err?.response?.data.errorCode ?? 0) {
                            		
                            			case 111:
                            				window.location.replace("/login");
                            				break;
                            			default:
                            				window.location.replace(location.origin);
                            				break;
                            		}
                            	}
                            },
                            type: DialogType.redirect,
                        });
                    } else {
                        handleError(err);
                    }

                    reject(err);
                }) as Promise<AxiosResponse<T> | void>;
        });
    };

    const getRequestConfig = (method: Method, data: unknown, headers: Record<string, string>, signal?: AbortSignal) => {
        if (method == "GET") {
            return { ...getHeaders(headers), params: data, signal };
        }
        if (method == "DELETE") {
            return {
                ...getHeaders(headers),
                data,
                signal,
            };
        }
        return { ...getHeaders(headers), signal };
    };

    const getHeaders = (optionalHeaders: Record<string, string>) => {
        return {
            headers: {
                ...optionalHeaders,
                "Accept-Language": i18n.global.locale.value,
                "x-orno-user": getUser(),
            },
        };
    };

    const setErrorMessage = (err: AxiosError<any, unknown>) => {
        
    	switch (err.response?.data?.errorCode) {
    		case 9006:
    			err.message = err.response?.data?.message;
    			break;
    		default:
    		if (i18n.global.te(`errorCodes.${err.response?.data?.errorCode}`)) {
    
    			err.message = i18n.global.t(`errorCodes.${err.response?.data?.errorCode}`);
    			
    		} else if (devMode) {
        		err.message = err.toString();
    		} else {
        		err.message = i18n.global.t("errorCodes.-1");
    		}
    	}
    };

    const handleError = (err: AxiosError) => {
        if (err?.response?.status === 420) {
            serviceUnavailable.value = true;
        }
        setErrorMessage(err);
        if (!displayErrors && err.response) {
            return;
        }

        if (err.message && err.code !== "ERR_CANCELED") {
        }
    };

    const delay = (t: number, value: unknown = undefined) => {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, value), t);
        });
    };

    type PromiseFunction<T> = () => Promise<T>;

    const retry = <T>(call: PromiseFunction<T>, count = 3): Promise<T> => {
        let lastError: AxiosError;
        return new Promise((resolve, reject) => {
            if (count > 0) {
                call()
                    .then((data: T) => resolve(data))
                    .catch((err) => {
                        lastError = err;
                        delay(500).then(() => retry(call, count - 1));
                    });
            } else {
                reject(lastError);
            }
        });
    };

    return {
        post,
        get,
        del,
        patch,
        put,
        removeToken,
        delay,
        retry,
        serviceUnavailable: readonly(serviceUnavailable),
    };
};

export default useApi;

import { OpenTicketState } from "@/models/front/TicketState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        identifier: "",
        numberOfTickets: 1,
        bookingNumber: -1,
    } as OpenTicketState;
};

export const useOpenTicketStore = defineStore("openTicket", {
    state: () => getInitialState(),
    getters: {
        getOpenTicketState(state): OpenTicketState {
            return state;
        },
        getIdentifier(state): string {
            return Array(state.numberOfTickets).fill(state.identifier).join('&');
        },
        getNumberOfTickets(state): number {
            return state.numberOfTickets;
        },
    },
    actions: {
        setIdentifier(identifier: string) {
            this.identifier = identifier;
        },
        setNumberOfTickets(numberOfTickets: number) {
        	this.numberOfTickets = numberOfTickets;
        }
    },
    persist: {
        storage: sessionStorage,
    },
});

export const dialogKey = "display-dialog";

export interface DialogMessage {
    title: string;
    message: string;
    yes: string;
    no?: string;
    callback?: Function;
    type: DialogType;
}

export enum DialogType {
    danger = "danger",
    info = "info",
    redirect = "redirect",
    warning = "warning",
}

import { ref } from "vue";
import { useMutation, useQueryClient } from "vue-query";
import useApi from "../useApi";
import { PrepareCancelResponse } from "@/models/api/BookingModelResponse";

const useCancelBooking = () => {
    const { post } = useApi();
    const queryClient = useQueryClient();

    const isLoading = ref(false);
    const prepareCancel = (bookingNumber: number) => {
        isLoading.value = true;
        return post<PrepareCancelResponse>("cancel/prepare", { bookingNumber })
            .then((resp) => {
                return resp.data;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const confirmCancel = useMutation(
        (bookingNumber: number) => {
            return post("cancel/confirm", { bookingNumber });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("bookings");
            },
        },
    );

    return {
        prepareCancel,
        confirmCancel,
        isLoading,
    };
};

export default useCancelBooking;

import { BookingCompleteDto } from "../api/BookingCompleteDto";
import { BookingDeparture } from "./BookingDeparture";

export class BookingComplete {
    bookingNumber: number;
    departures: BookingDeparture[];
    isPaid: boolean;
    leftToPay: number;
    paid: number;
    isValid: boolean;
    totalPrice: number;
	usedAt?: string;
	
    constructor(resp: BookingCompleteDto) {
        this.bookingNumber = resp.bookingNumber;
        this.departures = resp.departures.map((d) => new BookingDeparture(d));
        this.isPaid = resp.isPaid;
        this.isValid = resp.isValid;
        this.leftToPay = resp.leftToPay;
        this.paid = resp.paid;
        this.totalPrice = resp.totalPrice;
        this.usedAt = resp.usedAt;
    }
}

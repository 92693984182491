import { VehicleCapacity } from "@/models/Enums";
import { addMinutes } from "date-fns";
import { DepartureModelResponse } from "../api/DepartureModelResponse";
import { BookingResource } from "./BookingResource";

export class DepartureModel {
    id: string;
    ship: string;
    route: string;
    identifier: string;
    departureDate: Date;
    arrivalDate: Date;
    capacity: VehicleCapacity;
    prices: BookingResource[];
    isBookable: boolean;

    constructor(response: DepartureModelResponse) {
        this.id = response.id;
        this.isBookable = response.isBookable;
        this.ship = response.ship;
        this.route = response.route;
        this.identifier = response.identifier;
        this.departureDate = new Date(response.dateTime);
        this.arrivalDate = addMinutes(new Date(response.dateTime), 30);
        this.capacity = VehicleCapacity[response.capacity as keyof typeof VehicleCapacity];
        this.prices = response.prices.map((p) => new BookingResource(p));
    }
}

import { UserDetailsResponse } from "../api/UserDetailsResponse";
import { CoPassenger } from "./CoPassenger";

export class UserDetails {
    firstName: string;
    lastName: string;
    address: string;
    zip: number;
    city: string;
    mobilePhone: string;
    email: string;
    password?: string;
    repeatPassword?: string;
    
    constructor(resp: UserDetailsResponse) {
        this.firstName = resp.firstName;
        this.lastName = resp.lastName;
        this.address = resp.address;
        this.zip = resp.zip;
        this.city = resp.city;
        this.mobilePhone = resp.mobilePhone;
        this.email = resp.email;
    }
}

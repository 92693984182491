import { useQuery } from "vue-query";
import useApi from "../useApi";
import { Complete } from "@/models/front/Complete";
import { CompleteResponse } from "@/models/api/CompleteResponse";
import { computed } from "vue";

const useCompleteQuery = (id?: string, sessionless?: boolean) => {
    const { get } = useApi();

	const url = computed(() => (sessionless ?? false) ? `tickets/?id=${id}` : `complete/?id=${id}`);

    const complete = () => {
        return get<CompleteResponse>(url.value, "").then((resp) => {
            return new Complete(resp.data);

        })
        .catch((err) => {

        	window.location.href = "/?s=5&failed=true";
        })
    };

    return {
        ...useQuery(["complete", id], complete, {
            enabled: !!id,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60,
            retry: false,
        }),
    };
};

export default useCompleteQuery;

import { BookRequest } from "@/models/front/BookRequest";
import { ref } from "vue";
import useApi from "../useApi";

const useBook = () => {
    const isLoading = ref(false);

    const { post } = useApi();

    const book = (request: BookRequest) => {
        isLoading.value = true;
        return post("book", request).finally(() => {
            isLoading.value = false;
        });
    };
   	
    return {
        book,
    };
};

export default useBook;

import { useQuery } from "vue-query";
import useApi from "../useApi";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { Card, CardsResponse, CustomerCard } from "@/models/front/Cards";

const useGetCardsQuery = () => {
    const { get } = useApi();

    const getCards = () => {
        return get<CardsResponse[]>("profile/cards", "").then((resp) => {
            const cards = resp.data.map((c) => new CustomerCard(c));

            return new Card(cards);
        });
    };

    const userStore = useUserStore();
    const cacheKey = computed(() => ["cards", userStore.user.userName]);

    return {
        ...useQuery(cacheKey.value, getCards, {
            refetchOnWindowFocus: false,
            retry: false,
        }),
    };
};

export default useGetCardsQuery;

import { PaymentAlternativesResponse } from "@/models/api/PaymentAlternativesResponse";
import { PaymentResponse } from "@/models/api/PaymentResponse";
import { PaymentAlternative } from "@/models/front/PaymentAlternative";
import { ref } from "vue";
import { useQuery } from "vue-query";
import useApi from "../useApi";
import { useBookingStore } from "@/store/booking";

export type StartPaymentRequest = {
    token: string;
    reference?: string;
};

const usePaymentQuery = (id?: string) => {
    const { post } = useApi();

    const isInitializingPayment = ref(false);
    const bookingStore = useBookingStore();

    const getPaymentAlternatives = () => {
        return post<PaymentAlternativesResponse>("payment/alternatives", { id }).then((resp) => {
            return new PaymentAlternative(resp.data);
        });
    };

    const startPayment = (paymentTokens: StartPaymentRequest[]) => {
        isInitializingPayment.value = true;

        return post<PaymentResponse>("payment/start", { paymentTokens, reference: id })
            .then((resp) => {
                bookingStore.setBookingNumber(resp.data.bookingNumber);
                window.location.href = resp.data.redirectUrl;
            })
            .finally(() => {
                isInitializingPayment.value = false;
            });
    };

    return {
        ...useQuery("payment", getPaymentAlternatives, {
            refetchOnWindowFocus: false,
            retry: false,
        }),
        startPayment,
        isInitializingPayment,
    };
};

export default usePaymentQuery;

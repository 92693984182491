import { CardType } from "../Enums";
import { PaymentAlternativeType } from "@/models/Enums";
import { PaymentAlternativeResponse } from "../api/PaymentAlternativeResponse";
import { PaymentAlternativesResponse } from "../api/PaymentAlternativesResponse";

export class PaymentAlternative {
    usedCards: Alternative[];
    alternatives: Alternative[];
    leftToPay: number;
	amd: number;
	
    constructor(resp: PaymentAlternativesResponse) {
        this.usedCards = resp.paymentAlternatives.filter((c) => [PaymentAlternativeType.Cash, PaymentAlternativeType.Invoice].indexOf(c.type) === -1).map((c) => new Alternative(c));
        this.alternatives = resp.paymentAlternatives.filter((c) => [PaymentAlternativeType.Cash, PaymentAlternativeType.Invoice].indexOf(c.type) !== -1).map((c) => new Alternative(c));
        this.leftToPay = resp.leftToPay;
        this.amd = resp.amendmentFee;
    }
}
export class Alternative {
    type: PaymentAlternativeType;
    travelCardType?: CardType;
    description: string;
    token: string;
    label?: string;
    icon?: string;

    constructor(resp: PaymentAlternativeResponse) {
        this.type = resp.type;
        this.travelCardType = resp?.travelCardType;
        this.description = getPaymentDescription(resp.description);
        this.token = resp.token;
        this.icon = getPaymentIcon(this.description);
    }
}

const getPaymentIcon = (description: string) => {
    switch (description) {
        case "Swish":
            return "swish";
        case "Kort":
            return "carbon/card";
        case "Partibiljett (person)":
            return "carbon/ticket";
    }
};

export const getPaymentDescription = (description: string) => {
    if (description.includes("Partibiljett")) return "MultiTripCard";
    if (description.includes("Årskort")) return "TravelCard";
    else return description;
};

import { BookingDetailsResponse } from "../api/BookingDetailsResponse";

export class BookingDetails {
    address: string;
    city: string;
    email: string;
    name: string;
    zip: string;
    mobilePhone: string;
    
    constructor(resp: BookingDetailsResponse) {
        this.address = resp.address;
        this.city = resp.city;
        this.email = resp.email;
        this.name = resp.name;
        this.zip = resp.zip;
        this.mobilePhone = resp.mobilePhone;
    }
}

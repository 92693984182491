import { eventBusKey } from "@/models/Symbols/Eventbus";
import mitt, { Emitter, EventType } from "mitt";
import { Plugin } from "vue";

const emitter = (window as any).mitt || mitt();
(window as any).mitt = emitter;

declare module "vue" {
    export interface ComponentCustomProperties {
        $eventBus: Emitter<Record<EventType, unknown>>;
    }
}

const eventbusPlugin: Plugin = {
    install: (app) => {
        app.config.globalProperties.$eventBus = emitter;
        app.provide(eventBusKey, emitter);
    },
};

export default eventbusPlugin;

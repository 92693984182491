import { routes } from "@/config/routes";
import { Direction, TripType } from "@/models/Enums";
import { BookingAmend } from "@/models/front/BookingAmend";
import { BookingPart, BookingState } from "@/models/front/BookingState";
import { defineStore } from "pinia";
import { usePassengersStore } from "./passengers";
import { useVehiclesStore } from "./vehicles";
import { useDepartureStore } from "./departure";
import { useOpenTicketStore } from "./openTicket";

const getInitialState = () => {
    return {
        productCode: "",
        bookingNumber: -1,
        isAmendment: false,
        tripType: TripType.Return,
        outward: {
            route: "",
            date: undefined,
            id: "",
            isLocked: false,
        } as BookingPart,
        return: {
            route: "",
            date: undefined,
            id: "",
            isLocked: false,
        } as BookingPart,
        isBusiness: false,
        termsAccepted: false,
    } as BookingState;
};

export const useBookingStore = defineStore("booking", {
    state: () => getInitialState(),
    getters: {
        getBookingNumber(): number {
            return this.bookingNumber;
        },
        getBooking(state): BookingState {
            return state as BookingState;
        },
        getRoute:
            (state) =>
            (direction: Direction): string => {
                if (direction === Direction.Outward) {
                    return state.outward.route;
                }
                return state.return.route;
            },
        getDate:
            (state) =>
            (direction: Direction): Date | undefined => {
                if (direction === Direction.Outward) {
                    return state.outward.date ? new Date(state.outward.date as Date) : undefined;
                }
                return state.return.date ? new Date(state.return.date as Date) : undefined;
            },

        getProductCode(): string {
            return this.productCode;
        },

        getTrips(): Direction[] {
            const trips = [];

            trips.push(Direction.Outward);

            if (this.tripType === TripType.Return) {
                trips.push(Direction.Return);
            }

            return trips;
        },
        getIsBusiness(): boolean {
            return this.isBusiness;
        },
        getIsReturn(): boolean {
            return this.tripType === TripType.Return;
        },
        getIsAmendment(): boolean {
            return this.isAmendment;
        },
    },
    actions: {
    	setRoute(route: string) {
    		
    		if ((route?.length ?? 0) === 4) {
            	this.setTripType(TripType.Return);
            	this.outward.route = route;
            	this.return.route = `${route.substring(2, 4)}${route.substring(0, 2)}`
            }
    	},
        setTripDetails(outwardRoute: string, outwardDate: Date, returnRoute: string, isReturn: boolean, returnDate?: Date) {
            if (!isReturn) {
                this.setTripType(TripType.Single);
                this.return.date = undefined;
            } else {
                this.return.date = returnDate;
                this.setTripType(TripType.Return);
            }
            this.outward.route = outwardRoute;
            this.outward.date = outwardDate;
            this.return.route = returnRoute;
        },
        setIsAmendment(isAmendment: boolean) {
            this.isAmendment = isAmendment;
        },
        setBookingNumber(bookingNumber: number) {
            this.bookingNumber = bookingNumber;
        },
        setAmendment(bookingAmend: BookingAmend) {
            this.isAmendment = true;
            this.bookingNumber = bookingAmend.bookingNumber;
            const arrivalRoute = routes.find((r) => r.value !== bookingAmend.outward.route);
            this.setTripDetails(
                bookingAmend.outward.route,
                new Date(bookingAmend.outward.departureDate),
                arrivalRoute?.value ?? "",
                bookingAmend.isReturn,
            );

            this.outward.id = bookingAmend.outward.id;
            this.outward.isLocked = bookingAmend.outward.isLocked;
            if (bookingAmend.isReturn && bookingAmend.return?.departureDate) {
                this.return.date = new Date(bookingAmend.return?.departureDate);
                this.return.id = bookingAmend.return.id;
            }
        },
        setTripType(tripType: TripType) {
            this.tripType = tripType;
        },
        setProductCode(productCode: string) {
            this.productCode = productCode;
        },
        setDate(direction: Direction, date?: Date) {
            if (direction === Direction.Outward) {
                this.outward.date = date ? date : undefined;
            } else {
                this.return.date = date ? date : undefined;
            }
        },
        setIsBusiness(isBusinessBooking: boolean) {
            this.isBusiness = isBusinessBooking;
        },
        setTermsAccepted(val: boolean) {
            this.termsAccepted = val;
        },
        resetBookingState() {
            usePassengersStore().$reset();
            useVehiclesStore().$reset();
            useDepartureStore().$reset();
            useOpenTicketStore().$reset();
            
            const route = this.outward.route;
            this.$reset();
            
            this.setRoute(route);
        },
    },
    persist: {
        storage: sessionStorage,
    },
});

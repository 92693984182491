import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export interface BookingStep {
    title: string;
    number: number;
}

const useBookingNavigation = (isOpenTicket?: boolean) => {
    const router = useRouter();

    const { t } = useI18n();

    const steps = computed(() => {
        let defaultSteps: BookingStep[] = [];
        if (isOpenTicket) {
            defaultSteps = [
                {
                    number: 1,
                    title: t("bookingFlow.navigation.openTicket"),
                },
                {
                    number: 2,
                    title: t("bookingFlow.navigation.payment"),
                },
            ];
        } else {
            defaultSteps = [
                {
                    number: 1,
                    title: t("bookingFlow.navigation.tripDetails"),
                },
                {
                    number: 2,
                    title: t("bookingFlow.navigation.travelers"),
                },
                {
                    number: 3,
                    title: t("bookingFlow.navigation.departures"),
                },
                {
                    number: 4,
                    title: t("bookingFlow.navigation.customerDetails"),
                },
                {
                    number: 5,
                    title: t("bookingFlow.navigation.payment"),
                },
            ];
        }
        return defaultSteps;
    });

    const currentStep = computed(() => {
        const step = parseInt(router.currentRoute.value.query.s?.toString() ?? "1");
        return steps.value.find((v) => v.number === step) ?? steps.value[0];
    });

    const nextStep = () => {
        goToStep(currentStep.value.number + 1);
    };

    const prevStep = () => {
        goToStep(currentStep.value.number - 1 || 1);
    };

    const goToStep = (step: number) => {
        if (step > steps.value.length) return;
        router.push({
            path: router.currentRoute.value.path,
            query: {
                s: step,
            },
        });
    };

    const isCheckout = computed(() => {
        return currentStep.value.number === 5;
    });

    return { nextStep, prevStep, goToStep, steps, currentStep, isCheckout };
};

export default useBookingNavigation;

import { CardType } from "../Enums";
import { AvailableCardResponse } from "../api/AvailableCardsResponse";
import { getCardType } from "./Cards";

export class AvailableCard {
    description: string;
    identifier: string;
    price: number;
    totalAmount: number;
    type: CardType;
    subType: string;
    usageAmount: number;

    constructor(resp: AvailableCardResponse) {
        this.description = resp.description;
        this.identifier = resp.identifier;
        this.price = resp.price;
        this.totalAmount = resp.totalAmount;
        this.type = getCardType(resp.type);
        this.usageAmount = resp.usageAmount;
        this.subType = resp.subType;
    }
}
